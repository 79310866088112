:root {
  --navColor: rgb(180, 152, 152);
}

body,
html {
  margin: 0;
  padding: 0;
  background-color: #f5f5f5;
}

.BG {
  background-color: #f5f5f5;
}

/* Universal */

.full {
  height: 100%;
  width: 100%;
}

.centerElement {
  display: flex;
  justify-content: center;
  align-items: center;
}

.textDectorationNone {
  text-decoration: none;
}


/* ----HEADER CSS---- */
.headerStyle {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  height: 50px;
  background-color: #f5f5f5;
  border-bottom: 1px solid black;
}

.headerTextStyle {
  font-family: Arial, Helvetica, sans-serif;
  /* Use a clean sans-serif font */
  font-size: 16px;
  /* Adjust the font size as needed */
  font-weight: normal;
  /* Regular font weight */
  color: #333;
  /* Dark gray text color */
  line-height: 1.5;
  /* Comfortable line height */
  margin: 0;
  /* Reset margin for consistent spacing */
  text-decoration: none;
  text-shadow: 0.1px 0.1px 0.1px black;
}

.bottomHeader {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100px;
  background-color: #f5f5f5;
  border-bottom: 1px solid black;
}

.bottomHeader1 {
  display: flex;
  align-items: center;
  padding-left: 200px;
  width: 100%;
}

.subNavStyle {
  font-family: Arial, sans-serif;
  /* Use a clean sans-serif font */
  font-size: 16px;
  /* Adjust the font size as needed */
  font-weight: normal;
  /* Regular font weight */
  color: #333;
  /* Dark gray text color */
  line-height: 1.5;
  /* Comfortable line height */
  margin: 0;
  /* Reset margin for consistent spacing */
  text-decoration: none;
  text-shadow: 0.1px 0.1px 0.1px black;
  padding-top: 0px;
  text-decoration: underline;
}

.buffer {
  margin: 0px 10px;
  font-size: 12px;
  padding-top: 1px;
}

.buffer1 {
  margin: 0px 3px;
}

.bigNav {
  display: flex;
}

.miniNav {
  display: none;
}

.side-nav-overlay {
  position: fixed;
  /* Fixed positioning to cover the viewport */
  top: 0;
  left: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  background-color: #f5f5f5;
  /* Semi-transparent background */
  z-index: 1000;
  /* Place above other content (adjust as needed) */
  display: flex;
  justify-content: center;
  align-items: center;
}

.side-nav-content {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  /* Adjust the width as desired */
  height: 100%;
  background-color: white;
  padding: 20px 0px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.closeSideNavStyle {
  align-self: self-end;
  text-decoration: none;
  color: black;
  font-size: 20px;
  padding-right: 25px;
  padding-bottom: 10px;
}

.linksSideNav {
  text-decoration: none;
  font-size: 20px;
  padding-bottom: 10px;
  border-top: 1px solid black;
  color: black;
  width: 100%;
  padding-left: 20px;
}

.linksSideNavNoBorder {
  text-decoration: none;
  font-size: 20px;
  padding-bottom: 10px;
  color: black;
  width: 100%;
  padding-left: 20px;
}

/* Navagation Divs inside of header */
.divLinksA {
  display: flex;
  justify-content: space-between;
}

.divLinksB {
  display: flex;
  align-items: center;
  border-left: 1px solid black;
  text-decoration: none;
}

/* Changing Nav Links to a differnt style than normal underline blue */
.navStyle {
  font-family: Arial, sans-serif;
  /* Use a clean sans-serif font */
  font-size: 16px;
  /* Adjust the font size as needed */
  font-weight: normal;
  /* Regular font weight */
  color: #333;
  /* Dark gray text color */
  line-height: 1.5;
  /* Comfortable line height */
  margin: 0;
  /* Reset margin for consistent spacing */
  text-decoration: none;
  padding-left: 10px;
  text-shadow: 0.1px 0.1px 0.1px black;
}

/* On Hover divLinks */
.divLinksB:hover,
.divLinksB:hover p {
  background-color: black;
  color: white;
  transition: colo 0.3s
}

/* Header CSS Hover Elements */
.onHover:hover {
  background-color: black;
}

.onHover:hover,
.onHover:hover p {
  background-color: black;
  color: white;
}

.navStyle:hover {
  color: white;
}

.subNavStyle:hover {
  font-weight: bold;
}

/* ----PORTFOLIO CSS----- */

.portfolioContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  transition: top 0.3s ease;
}

.square {
  flex: 1 0 calc(25% - 20px);
  /* 4 columns, with 20px of margin */
  background-color: #f5f5f5;
  aspect-ratio: 1/1;
  /* Create a square aspect ratio */
  transition: flex 0.3s ease;
  border-right: 1px solid black;
  border-bottom: 1px solid black;
  text-decoration: none;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.thumb-title {
  margin-top: 0;
  font-size: 20px;
  color: #f5f5f5;
  text-align: left;
  text-decoration: none;
  width: 100%;
  padding-left: 30px;
  padding-top: 20px;
  padding-right: 30px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: normal;
}

.thumb-subtitle {
  margin-bottom: 0;
  font-size: 20px;
  color: #f5f5f5;
  text-align: right;
  text-decoration: none;
  width: 100%;
  padding-right: 30px;
  padding-bottom: 30px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: normal;
}

.fakeThumbnail {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-decoration: none;
  color: black;
}

.image-container {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 20px;
}

.image-container img {
  max-width: 100%;
  height: auto;
  border-radius: 5px;
}

/* CONTACT CSS SECTION */
.business-card {
  display: flex;
  justify-content: center;
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.name {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.contact-info {
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-top: 10px;
  width: 300px;
}

.emailText {
  padding: 0px 10px;
}

.emailLink {
  display: inline-block;
  text-decoration: none;
  color: black;
  font-size: 16px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.emailLink:hover {
  font-weight: bold;
}

/* Hover Effects on Portfolio Elements */

.square:hover,
.square:hover h1,
.square:hover h2 {
  background-color: black;
  color: white;
}

.linksSideNav:hover {
  background-color: black;
  color: white;
}

.linksSideNavNoBorder:hover {
  background-color: black;
  color: white;
}

/* ABOUT ME CSS SECTION */
.paper-container {
  height: 400px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 25px 200px;
}

.aboutHeaders {
  width: 100%;
  background-color: #f5f5f5;
  font-size: 22px;
  margin: 0px;
}

.bulletPointBlock {
  margin-left: -23px;
}

.lineBetween {
  border-left: 2px solid black;
  height: 100%;
  margin-left: -12px;
  padding-left: 12px;
}

.spacer {
  height: 30px;
}

/* FOOTER CSS */
/* Footer Styling/Fixing footer to bottom of page */
.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 30px;
  background-color: #f5f5f5;
  border-top: 1px solid black;
}

.footer-links {
  margin: 0px 20px;
}

@media(max-width: 1700px) {
  .square {
    flex-basis: calc(33% - 20px);
  }
}

@media(max-width: 1315px) {
  .square {
    flex-basis: calc(50% - 20px);
  }
}

@media(max-width: 759px) {
  .square {
    flex-basis: calc(100% - 20px);
  }

  .paper-container {
    padding: 20px 100px;
    transition: 0.3;
  }

  .bottomHeader1 {
    padding-left: 100px;
  }
}

@media(max-width: 670px) {
  .bigNav {
    display: none;
  }

  .miniNav {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 50px;
  }

  .miniNavStyle {
    color: black;
    font-size: 16px;
    text-decoration: none;
  }
}

@media(max-width: 501px) {
  .paper-container {
    padding: 20px 25px;
    transition: 0.3;
  }

  .bottomHeader1 {
    padding-left: 0px;
    justify-content: center;
  }

  .subHeader-container{
    display: flex;
    justify-content: center;
  }

  .subNavStyle{
    font-size: 14px;
  }

  .headerTextStyle{
    font-size:14px;
  }
}

.miniNavStyle:hover {
  font-weight: bold;
}

.closeSideNavStyle:hover {
  font-weight: bold;
}